import { AIRCRAFT_URLS } from "../configs/constants";
const AircraftsList = [
  {
    images: [
      {
        classification: ["5"],
        smallUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/7/25/baggage.png"
        ]
      },
      {
        classification: ["1"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/7/27/internal_1.jpg"
        ]
      },
      {
        classification: ["6"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/7/28/internal_2.jpg"
        ]
      },
      {
        classification: ["7"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/7/29/internal_3.jpg"
        ]
      },
      {
        classification: ["4"],
        smallUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/7/30/sketch.png"
        ]
      },
      {
        classification: ["20"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/7/191/360-tour-icon.png"
        ]
      },
      {
        classification: ["19"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/7/192/global-6000-floorplan-day.png"
        ]
      },
      {
        classification: ["18"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/7/193/global-6000-floorplan-night.png"
        ]
      },
      {
        classification: ["8"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/7/194/GlobalXRS6000-Carousel_1.jpg"
        ]
      },
      {
        classification: ["9"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/7/195/GlobalXRS6000-Carousel_2.jpg"
        ]
      },
      {
        classification: ["10"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/7/196/GlobalXRS6000-Carousel_3.jpg"
        ]
      },
      {
        classification: ["11"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/7/197/GlobalXRS6000-Carousel_4.jpg"
        ]
      },
      {
        classification: ["12"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/7/198/GlobalXRS6000-Carousel_5.jpg"
        ]
      },
      {
        classification: ["13"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/7/199/GlobalXRS6000-Carousel_6.jpg"
        ]
      },
      {
        classification: ["14"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/7/200/GlobalXRS6000-Carousel_7.jpg"
        ]
      },
      {
        classification: ["15"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/7/201/GlobalXRS6000-Carousel_8.jpg"
        ]
      },
      {
        classification: ["3"],
        smallUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/7/255/icon_6000-new.jpg"
        ]
      }
    ],
    id: 1718,
    manufacturer: "Alameda PLC",
    name: "Global 7500",
    displayName: "Global 7500",
    code: "GL7T",
    range: "14260",
    speed: "955",
    maxNumberOfPassengers: "14",
    maxNumberOfPassengersSleeping: "8",
    maxFlightTime: "780",
    turnaroundTimeMinutes: "30",
    speedFirst30minBlock: "668.576",
    speedSecond30minBlock: "838.96",
    taxiTimeMinutes: "20",
    cruisingAltitude: "590.0",
    maxAltitudeFt: "51000.0",
    maxAltitudeMetres: "15545.0",
    maxRangeKm: "14260",
    maxRangeNm: "7700",
    rank: "9",
    lastUpdated: "1540558708713",
    availableOnCustomerApp: "true",
    tourURL: AIRCRAFT_URLS.LINK_TO_GLOBAL_XRS_7500
  },
  {
    images: [
      {
        classification: ["5"],
        smallUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/7/25/baggage.png"
        ]
      },
      {
        classification: ["1"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/7/27/internal_1.jpg"
        ]
      },
      {
        classification: ["6"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/7/28/internal_2.jpg"
        ]
      },
      {
        classification: ["7"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/7/29/internal_3.jpg"
        ]
      },
      {
        classification: ["4"],
        smallUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/7/30/sketch.png"
        ]
      },
      {
        classification: ["20"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/7/191/360-tour-icon.png"
        ]
      },
      {
        classification: ["19"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/7/192/global-6000-floorplan-day.png"
        ]
      },
      {
        classification: ["18"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/7/193/global-6000-floorplan-night.png"
        ]
      },
      {
        classification: ["8"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/7/194/GlobalXRS6000-Carousel_1.jpg"
        ]
      },
      {
        classification: ["9"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/7/195/GlobalXRS6000-Carousel_2.jpg"
        ]
      },
      {
        classification: ["10"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/7/196/GlobalXRS6000-Carousel_3.jpg"
        ]
      },
      {
        classification: ["11"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/7/197/GlobalXRS6000-Carousel_4.jpg"
        ]
      },
      {
        classification: ["12"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/7/198/GlobalXRS6000-Carousel_5.jpg"
        ]
      },
      {
        classification: ["13"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/7/199/GlobalXRS6000-Carousel_6.jpg"
        ]
      },
      {
        classification: ["14"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/7/200/GlobalXRS6000-Carousel_7.jpg"
        ]
      },
      {
        classification: ["15"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/7/201/GlobalXRS6000-Carousel_8.jpg"
        ]
      },
      {
        classification: ["3"],
        smallUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/7/255/icon_6000-new.jpg"
        ]
      }
    ],
    id: 7,
    manufacturer: "Alameda PLC",
    name: "Global 6000",
    displayName: "Global 6000",
    code: "GLEX",
    range: "11110",
    speed: "838.96",
    maxNumberOfPassengers: "14",
    maxNumberOfPassengersSleeping: "7",
    maxFlightTime: "780",
    turnaroundTimeMinutes: "30",
    speedFirst30minBlock: "668.576",
    speedSecond30minBlock: "838.96",
    taxiTimeMinutes: "20",
    cruisingAltitude: "590.0",
    maxAltitudeFt: "51000.0",
    maxAltitudeMetres: "15545.0",
    maxRangeKm: "11110",
    maxRangeNm: "5998.0",
    rank: "9",
    lastUpdated: "1540558708713",
    availableOnCustomerApp: "true",
    tourURL: AIRCRAFT_URLS.LINK_TO_GLOBAL_XRS_6000
  },
  {
    images: [
      {
        classification: ["5"],
        smallUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/16/19/baggage.png"
        ]
      },
      {
        classification: ["4"],
        smallUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/16/20/Global-5000-sketch.jpg"
        ]
      },
      {
        classification: ["1"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/16/22/internal_1.jpg"
        ]
      },
      {
        classification: ["6"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/16/23/internal_2.jpg"
        ]
      },
      {
        classification: ["7"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/16/24/internal_3.jpg"
        ]
      },
      {
        classification: ["20"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/16/178/360-tour-icon.png"
        ]
      },
      {
        classification: ["8"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/16/179/Global5000-Carousel_1.jpg"
        ]
      },
      {
        classification: ["9"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/16/180/Global5000-Carousel_2.jpg"
        ]
      },
      {
        classification: ["10"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/16/181/Global5000-Carousel_3.jpg"
        ]
      },
      {
        classification: ["11"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/16/182/Global5000-Carousel_4.jpg"
        ]
      },
      {
        classification: ["12"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/16/183/Global5000-Carousel_5.jpg"
        ]
      },
      {
        classification: ["13"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/16/184/Global5000-Carousel_6.jpg"
        ]
      },
      {
        classification: ["14"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/16/185/Global5000-Carousel_7.jpg"
        ]
      },
      {
        classification: ["15"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/16/186/Global5000-Carousel_8.jpg"
        ]
      },
      {
        classification: ["16"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/16/187/Global5000-Carousel_9.jpg"
        ]
      },
      {
        classification: ["17"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/16/188/Global5000-Carousel_10.jpg"
        ]
      },
      {
        classification: ["19"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/16/189/global-5000-floorplan-day.png"
        ]
      },
      {
        classification: ["18"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/16/190/global-5000-floorplan-night.png"
        ]
      },
      {
        classification: ["3"],
        smallUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/16/254/icon_5000-new.jpg"
        ]
      }
    ],
    id: 16,
    manufacturer: "Buckinghamshire Corporation",
    name: "Global 5000",
    displayName: "Global 5000",
    code: "GL5T",
    range: "9630",
    speed: "838.96",
    maxNumberOfPassengers: "13",
    maxNumberOfPassengersSleeping: "7",
    maxFlightTime: "660",
    turnaroundTimeMinutes: "30",
    speedFirst30minBlock: "668.576",
    speedSecond30minBlock: "838.96",
    taxiTimeMinutes: "10",
    cruisingAltitude: "590.0",
    maxAltitudeFt: "51000.0",
    maxAltitudeMetres: "15545.0",
    maxRangeKm: "9630",
    maxRangeNm: "5200.0",
    rank: "8",
    lastUpdated: "1540558702930",
    availableOnCustomerApp: "true",
    tourURL: AIRCRAFT_URLS.LINK_TO_GLOBAL_5000
  },
  {
    images: [
      {
        classification: ["5"],
        smallUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/6/13/baggage.png"
        ]
      },
      {
        classification: ["4"],
        smallUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/6/15/sketch.png"
        ]
      },
      {
        classification: ["1"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/6/16/internal_1.jpg"
        ]
      },
      {
        classification: ["6"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/6/17/internal_2.jpg"
        ]
      },
      {
        classification: ["7"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/6/18/internal_3.jpg"
        ]
      },
      {
        classification: ["20"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/6/170/360-tour-icon.png"
        ]
      },
      {
        classification: ["8"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/6/171/Challenger850-Carousel_1.jpg"
        ]
      },
      {
        classification: ["9"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/6/172/Challenger850-Carousel_2.jpg"
        ]
      },
      {
        classification: ["10"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/6/173/Challenger850-Carousel_3.jpg"
        ]
      },
      {
        classification: ["11"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/6/174/Challenger850-Carousel_4.jpg"
        ]
      },
      {
        classification: ["12"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/6/175/Challenger850-Carousel_5.jpg"
        ]
      },
      {
        classification: ["19"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/6/176/challenger-850-floorplan-day.png"
        ]
      },
      {
        classification: ["18"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/6/177/challenger-850-floorplan-night.png"
        ]
      },
      {
        classification: ["3"],
        smallUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/6/253/icon_850-new.jpg"
        ]
      }
    ],
    id: 6,
    manufacturer: "Alameda PLC",
    name: "Challenger 850",
    displayName: "Challenger 850",
    code: "CL850",
    range: "5200",
    speed: "783.0",
    maxNumberOfPassengers: "14",
    maxNumberOfPassengersSleeping: "7",
    maxFlightTime: "390",
    turnaroundTimeMinutes: "30",
    speedFirst30minBlock: "588.94",
    speedSecond30minBlock: "672.28",
    taxiTimeMinutes: "10",
    cruisingAltitude: "528.0",
    maxAltitudeFt: "41000.0",
    maxAltitudeMetres: "12497.0",
    maxRangeKm: "5200",
    maxRangeNm: "2807.0",
    rank: "7",
    lastUpdated: "1540558680480",
    availableOnCustomerApp: "true",
    tourURL: AIRCRAFT_URLS.LINK_TO_CHALLENGER_850
  },
  {
    images: [
      {
        classification: ["5"],
        smallUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/5/8/baggage.png"
        ]
      },
      {
        classification: ["4"],
        smallUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/5/9/sketch.png"
        ]
      },
      {
        classification: ["1"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/5/101/Challenger-605-1-1.png"
        ]
      },
      {
        classification: ["6"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/5/102/Challenger-605-2-1.png"
        ]
      },
      {
        classification: ["7"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/5/103/Challenger-605-3-1.png"
        ]
      },
      {
        classification: ["20"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/5/161/360-tour-icon.png"
        ]
      },
      {
        classification: ["8"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/5/162/Challenger605-Carousel_1.jpg"
        ]
      },
      {
        classification: ["9"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/5/163/Challenger605-Carousel_2.jpg"
        ]
      },
      {
        classification: ["10"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/5/164/Challenger605-Carousel_3.jpg"
        ]
      },
      {
        classification: ["11"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/5/165/Challenger605-Carousel_4.jpg"
        ]
      },
      {
        classification: ["12"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/5/166/Challenger605-Carousel_5.jpg"
        ]
      },
      {
        classification: ["13"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/5/167/Challenger605-Carousel_6.jpg"
        ]
      },
      {
        classification: ["19"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/5/168/challenger-605-floorplan-day.png"
        ]
      },
      {
        classification: ["18"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/5/169/challenger-605-floorplan-night.png"
        ]
      },
      {
        classification: ["3"],
        smallUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/5/252/icon_605-new.jpg"
        ]
      }
    ],
    id: 5,
    manufacturer: "Alameda PLC",
    name: "Challenger 605",
    displayName: "Challenger 605",
    code: "CL605",
    range: "7400",
    speed: "783.4",
    maxNumberOfPassengers: "12",
    maxNumberOfPassengersSleeping: "5",
    maxFlightTime: "480",
    turnaroundTimeMinutes: "30",
    speedFirst30minBlock: "616.72",
    speedSecond30minBlock: "690.8",
    taxiTimeMinutes: "10",
    cruisingAltitude: "541.0",
    maxAltitudeFt: "41000.0",
    maxAltitudeMetres: "12497.0",
    maxRangeKm: "7400",
    maxRangeNm: "3995.0",
    rank: "6",
    lastUpdated: "1540558671623",
    availableOnCustomerApp: "true",
    tourURL: AIRCRAFT_URLS.LINK_TO_CHALLENGER_605
  },
  {
    images: [
      {
        classification: ["5"],
        smallUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/10/2/baggage.png"
        ]
      },
      {
        classification: ["4"],
        smallUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/10/3/sketch.png"
        ]
      },
      {
        classification: ["1"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/10/4/Internal_1.jpg"
        ]
      },
      {
        classification: ["6"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/10/5/internal_2.jpg"
        ]
      },
      {
        classification: ["7"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/10/6/internal_3.jpg"
        ]
      },
      {
        classification: ["20"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/10/151/360-tour-icon.png"
        ]
      },
      {
        classification: ["8"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/10/152/Challenger350-Carousel_1.jpg"
        ]
      },
      {
        classification: ["9"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/10/153/Challenger350-Carousel_2.jpg"
        ]
      },
      {
        classification: ["10"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/10/154/Challenger350-Carousel_3.jpg"
        ]
      },
      {
        classification: ["11"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/10/155/Challenger350-Carousel_4.jpg"
        ]
      },
      {
        classification: ["12"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/10/156/Challenger350-Carousel_5.jpg"
        ]
      },
      {
        classification: ["13"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/10/157/Challenger350-Carousel_6.jpg"
        ]
      },
      {
        classification: ["14"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/10/158/Challenger350-Carousel_7.jpg"
        ]
      },
      {
        classification: ["19"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/10/159/challenger-350-floorplan-day.png"
        ]
      },
      {
        classification: ["18"],
        largeUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/10/160/challenger-350-floorplan-night.png"
        ]
      },
      {
        classification: ["3"],
        smallUrl: [
          "http://webserviceuat.vistajet.com/extranetServices/1.1/files/getfile?fileKey=aircraft_types/images/10/251/icon_350-new.jpg"
        ]
      }
    ],
    id: 10,
    manufacturer: "Guava Club",
    name: "Challenger 350",
    displayName: "Challenger 350",
    code: "CL350",
    range: "5926",
    speed: "783.4",
    maxNumberOfPassengers: "8",
    maxNumberOfPassengersSleeping: "3",
    maxFlightTime: "435",
    turnaroundTimeMinutes: "120",
    speedFirst30minBlock: "616.72",
    speedSecond30minBlock: "690.8",
    taxiTimeMinutes: "10",
    cruisingAltitude: "541.0",
    maxAltitudeFt: "45000.0",
    maxAltitudeMetres: "13716.0",
    maxRangeKm: "5926",
    maxRangeNm: "3200.0",
    rank: "4",
    lastUpdated: "1540558658178",
    availableOnCustomerApp: "true",
    tourURL: AIRCRAFT_URLS.LINK_TO_CHALLENGER_350
  }
];
export default AircraftsList;
